import React from 'react';
import { motion as m } from "framer-motion";
import { Link } from 'react-router-dom';

import chief1 from '../images/chief.JPG';
import chief2 from '../images/w4.jpg';
import chair from '../images/chair.JPG';
import vice from '../images/vice.JPG';
import log from '../images/logo.JPG';
import grp1 from '../images/grp.JPG';
import grp2 from '../images/w5.jpg';
import azure from '../images/azureeee.JPG';
import genai from '../images/GENAI-GRP.jpg';
import dig from '../images/digit.png';
import c6 from '../images/C6.jpg';
import c7 from '../images/C7.jpg';
import bg from '../images/S13.jpg'


import styles from './event.module.css'; // Make sure this path is correct

const Events = () => {
  return (
    <>
      <div className='sl'>
        <div className='slider'>
          <div className='slide-track'>
          <div className='slide'>
              <img src={chief1} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={chief2} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={chair} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={vice} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={log} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={c6} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={c7} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={grp1} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={grp2} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={chief1} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={chief2} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={chair} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={vice} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={log} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={c6} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={c7} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={grp1} alt='logo'/>
            </div>
            <div className='slide'>
              <img src={grp2} alt='logo'/>
            </div>


          </div>
        </div>
      </div>

      <div className={styles.cardContainer}>
        <m.div
          className={styles.card}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}>
          <img src={chief1} className={styles.card__background} alt="Chief Event" />
          <div className={styles.card__content}>
            <h3 className={styles.card__title}>INAUGURAL CEREMONY</h3>
            <p className={styles.card__description}>Excited to announce the SIST ACM SIGAI Chapter launched on April 8, 2024</p>
            <Link to="/Events/Event1">
              <button className={styles.card__button}><i className="fa-solid fa-arrow-right"></i></button>
            </Link>
            <div className={styles.card__date}>8 APR 2024</div> {/* Date element */}
          </div>
        </m.div>

        <m.div
          className={styles.card}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}>
          <img src={azure} className={styles.card__background} alt="Azure Cascade" />
          <div className={styles.card__content}>
            <h3 className={styles.card__title}>AZURE CASCADE</h3>
            <p className={styles.card__description}>The SIST ACM SIGAI Chapter’s first event was on July 8, 2024!</p>
            <Link to="/Events/Event2">
              <button className={styles.card__button}><i className="fa-solid fa-arrow-right"></i></button>
            </Link>
            <div className={styles.card__date}>8 JUL 2024</div> {/* Date element */}
          </div>
        </m.div>

        <m.div
          className={styles.card}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}>
          <img src={genai} className={styles.card__background} alt="Gen-AI Event" />
          <div className={styles.card__content}>
            <h3 className={styles.card__title}>DEEP DIVE INTO GEN-AI</h3>
            <p className={styles.card__description}>SIST ACM SIGAI Student Chapter’s first collaborative event on August 1, 2024!</p>
            <Link to="/Events/Event3">
              <button className={styles.card__button}><i className="fa-solid fa-arrow-right"></i></button>
            </Link>
            <div className={styles.card__date}>1 AUG 2024</div> {/* Date element */}
          </div>
        </m.div>

        <m.div
          className={styles.card}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}>
          <img src={dig} className={styles.card__background} alt="Digital Art" />
          <div className={styles.card__content}>
            <h3 className={styles.card__title}>DIGITAL ART</h3>
            <p className={styles.card__description}>SIST ACM SIGAI Student Chapter hosted digital art event on August 1, 2024,</p>
            <Link to="/Events/Event5">
              <button className={styles.card__button}><i className="fa-solid fa-arrow-right"></i></button>
            </Link>
            <div className={styles.card__date}>1 AUG 2024</div> {/* Date element */}
          </div>
        </m.div>
        <m.div
          className={styles.card}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}>
          <img src={bg} className={styles.card__background} alt="Space Day" />
          <div className={styles.card__content}>
            <h3 className={styles.card__title}>SPACE DAY</h3>
            <p className={styles.card__description}>SIST ACM SIGAI Student Chapter coordinated Space day event on August 22, 2024,</p>
            <Link to="/Events/SpaceDay">
              <button className={styles.card__button}><i className="fa-solid fa-arrow-right"></i></button>
            </Link>
            <div className={styles.card__date}>22 AUG 2024</div> {/* Date element */}
          </div>
        </m.div>
        <m.div
          className={styles.card}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.7 }}>
          <img src="https://i.postimg.cc/pLTybQMc/fre.avif" className={styles.card__background} alt="Synergy" />
          <div className={styles.card__content}>
            <h3 className={styles.card__title}>SYNERGY TO FRESHERS</h3>
            <p className={styles.card__description}>SIST ACM SIGAI conducted synergy for freshers event on September 4, 2024,</p>
            <Link to="/Events/Synergy">
              <button className={styles.card__button}><i className="fa-solid fa-arrow-right"></i></button>
            </Link>
            <div className={styles.card__date}>4 SEP 2024</div> {/* Date element */}
          </div>
        </m.div>
      </div>
    </>
  );
}

export default Events;
